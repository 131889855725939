import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkout from "./Checkout.js";
import { serviceList, serviceImgs } from './ServiceVars';

export class Cart extends React.Component{
  constructor(props){
    super(props);
    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    Date.prototype.subDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() - days);
        return date;
    }

    var sDate = new Date();
    if (sDate.getDay() > 2) {
      sDate = sDate.addDays(7-sDate.getDay());
    }
    var eDate = sDate.addDays(6);
    this.state = {
      step: this.props.step,
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      hasDog: false,
      hasGate: false,
      additional: "",
      allowedDate: sDate,
      startDate: sDate,
      endDate: eDate,
      auth: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.goBack = this.goBack.bind(this);
    this.proceed = this.proceed.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.skipStep = this.skipStep.bind(this);
  }
  removeFromCart(event){
    this.props.onChange(event.target.dataset.cart, event.target.dataset.id);
  }
  skipStep(){
    this.setState({
      step: 2
    })
  }
  goBack(){
    this.setState(previousState => ({
      step: previousState.step-1
    }))
  }
  proceed(){
    if (this.state.step === 2) {
      var errors = document.getElementsByClassName('is-invalid');
      var empty = document.getElementsByClassName('empty');
      for (var i = empty.length - 1; i >= 0; i--) {
        empty[i].classList.add("is-invalid");
      }
      if (errors.length > 0 || empty.length > 0) {
        document.getElementById("contactForm").classList.add("submitted");
      }else{
        this.setState(previousState => ({
          step: previousState.step+1
        }))
      }
    }else if (this.state.step === 4) {
      if (this.state.auth) {
        this.setState(previousState => ({
          step: previousState.step+1
        }))
      }else{
        document.getElementById('auth').classList.add("is-invalid");
      }
    }else{
      this.setState(previousState => ({
        step: previousState.step+1
      }))      
    }
    if (this.state.step > 5) {
      this.props.onConfirm();
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  handleBlur(event) {
    if(event.target.value.length === 0){
      event.target.classList.add("is-invalid");
    }else{
      event.target.classList.remove("empty");
      event.target.classList.remove("is-invalid");
    }
  }
  handleChange(date, event) {
    date = date.subDays(date.getDay());
    this.setState({
      startDate: date,
      endDate: date.addDays(6),
      selectedDate: date
    });
  }
  showAuth(){
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const address = this.props.address.selected;
    const week = new Date(this.state.startDate);
    const formattedDate = months[week.getMonth()]+" "+week.getDate()+", "+week.getFullYear();
    return "I authorise the staff at City Lawn access to the property "+address+" during the week of "+formattedDate+" to complete the selected services.";
  }
  render(){
    const cart = this.props.cart.map((product, index) =>
      <div className="cart-item row" key={index}>
        <div className="col-auto">
          <img alt={serviceList[product.id]} className="cart-icon" src={serviceImgs[product.id]} />
        </div>
        <span className="cart-name col">{product.name}</span>
        <span className="cart-price col-auto text-right">${product.price}.00</span>
        <div className="col-auto">
          <button onClick={this.removeFromCart} data-cart={index} data-id={product.id} className="btn remove"></button>
        </div>
      </div>
    );
    const step1 = (
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Shopping cart</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body cart">
            {this.props.selected}
            {cart}
            <div className="w-100 total clearfix">
              <table>
                <tbody>
                  {this.props.discount > 0 ? (
                    <tr className="discount">
                      <td>Discount</td>
                      <td>${parseFloat(Math.round(this.props.discount * 100) / 100).toFixed(2)}</td>
                    </tr>
                  ): null}
                  <tr>
                    <td>Total</td>
                    <td>${parseFloat(Math.round((this.props.total-this.props.discount) * 100) / 100).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={this.proceed} type="button" className="btn">Continue</button>
          </div>
        </div>
    )
    const step2 = (
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Enter contact information</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form id="contactForm">
            <div className="form-row">
              <div className="col-12 form-group">
                <label>First name</label>
                <input onChange={this.handleInputChange} onBlur={this.handleBlur} className="form-control empty" name="first_name" type="text" />
                <div className="invalid-feedback">Please enter your first name</div>
              </div>
              <div className="col-12 form-group">
                <label>Last name</label>
                <input onChange={this.handleInputChange} onBlur={this.handleBlur} className="form-control empty" name="last_name" type="text" />
                <div className="invalid-feedback">Please enter your last name</div>
              </div>
              <div className="col-12 form-group">
                <label>Email address</label>
                <input onChange={this.handleInputChange} onBlur={this.handleBlur} className="form-control empty" name="email_address" type="text" />
                <div className="invalid-feedback">Please enter a valid email address</div>
              </div>
              <div className="col-12 form-group">
                <label>Phone number</label>
                <input onChange={this.handleInputChange} onBlur={this.handleBlur} className="form-control empty" name="phone_number" type="tel" />
                <div className="invalid-feedback">Please enter your phone number</div>
              </div>
            </div>
          </form>
          </div>
          <div className="modal-footer">
            <button onClick={this.goBack} type="button" className="btn white">Return</button>
            <button onClick={this.proceed} type="button" className="btn">Continue</button>
          </div>
        </div>
    )
    const step3 = (
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Select week of service</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <DatePicker
              inline
              minDate={this.state.allowedDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              selected={this.state.startDate}
              onChange={this.handleChange}
            />
          </div>
          <div className="modal-footer">
            <button onClick={this.goBack} type="button" className="btn white float-left">Return</button>
            <button onClick={this.proceed} type="button" className="btn">Continue</button>
          </div>
        </div>
    )
    const step4 = (
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Enter additional information</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form id="contactForm">
              <div className="form-check" style={{'marginBottom' : 20}}>
                <input onChange={this.handleInputChange} className="form-check-input" type="checkbox" id="dog" name="hasDog" checked={this.state.hasDog} />
                <label className="form-check-label" htmlFor="dog">
                  I have a dog that is outside when I'm not home
                </label>
              </div>
              <div className="form-check" style={{'marginBottom' : 20}}>
                <input onChange={this.handleInputChange} className="form-check-input" type="checkbox" id="gate" name="hasGate" checked={this.state.hasGate} />
                <label className="form-check-label" htmlFor="gate">
                  I lock the backyard gate when not at home
                </label>
              </div>
              <div className="form-group">
                <label>Additional information</label>
                <textarea onChange={this.handleInputChange} className="form-control" name="additional" rows="3">
                </textarea>
              </div>
              <hr />
              <div className="form-check">
                <input onChange={this.handleInputChange} className="form-check-input" type="checkbox" id="auth" name="auth" checked={this.state.auth} />
                <label className="form-check-label" htmlFor="auth">
                  {this.showAuth()}
                </label>
              </div>
              {(this.state.hasDog === true || this.state.hasGate === true) ? (
                <div className="alert alert-warning">
                  <p>Please ensure gates are unlocked and pets are kept indoors at the time of service. We will provide a courtesy call the morning of the job. Any areas of your property that are not accessible when we arrive will not be serviced.</p>
                </div>
              ) : null}
            </form>
          </div>
          <div className="modal-footer">
            <button onClick={this.goBack} type="button" className="btn white">Return</button>
            <button onClick={this.proceed} type="button" className="btn">Continue</button>
          </div>
        </div>
    )
    const step5 = (
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Checkout</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Checkout cart={this.props.cart} address={this.props.address.selected} transaction_information={this.state} goBack={this.goBack} onSuccess={this.proceed} />
        </div>
    )
    const step6 = (
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Confirmation</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Success! You will receive an email confirmation shortly with more information.
          </div>
        </div>
    )
    let currentStep;
    switch (this.state.step){
      case 1:
        currentStep = step1
        break;
      case 2:
        currentStep = step2
        break;
      case 3:
        currentStep = step3
        break;
      case 4:
        currentStep = step4
        break;
      case 5:
        currentStep = step5
        break;
      case 6:
        currentStep = step6
        break;
      default:
        currentStep = step1
        break;
    }
    return (
    <div>
      <div className="modal fade" id="exampleModal">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                {currentStep}
            </div>
        </div>
      </div>
      <div className="slide-out" id="cartSlide">
        <span className="continue">Continue shopping</span>
        <div className="cart">
          {this.props.address.selected}
          {cart}
          <div className="w-100 total clearfix">
            <table>
              <tbody>
                {this.props.discount > 0 ? (
                  <tr className="discount">
                    <td>Discount</td>
                    <td>${parseFloat(Math.round(this.props.discount * 100) / 100).toFixed(2)}</td>
                  </tr>
                ): null}
                <tr>
                  <td>Total</td>
                  <td>${parseFloat(Math.round((this.props.total-this.props.discount) * 100) / 100).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button onClick={this.skipStep} className="btn" data-toggle="modal" data-target="#exampleModal" id="slideCheckout"><span className="icon"></span>Checkout</button>
      </div>
    </div>
    )
  }
}
export default Cart;