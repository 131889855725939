export const serviceList = [
  //"Lawn cutting and trimming",
  "Leaf raking",
  "Sidewalk edging",
  "Eavestrough cleaning"
]
export const serviceImgs = [
  //"images/cut.svg",
  "images/rake.svg",
  "images/edge.svg",
  "images/eavestrough.svg"
]
export const serviceBAs = [
  //"images/grass_before_after_title.jpg",
  "images/leaves_before_after_title.jpg",
  "images/edge_before_after_title.jpg",
  "images/eaves_before_after_title.jpg"
]
export const serviceDescriptions = [
  //"<p>Lawn cutting is an essential chore for most of us and sometimes we might want to take a week off. With just a few clicks, you can schedule an expert lawn cut and trimming and specify the week of service.</p>",
  "<p>Fall is here and the leaves are changing colour. Allow us to rake and bag your fallen leaves and bring them to a nearby drop-off location.</p>",
  "<p>Having a clean edge along your sidewalk is one of the easiest ways to improve your home’s curb appeal. We use a specialized machine to remove the lawn growing overtop of the sidewalk and dispose of the excess. </p>",
  "<p>Having your eavestrough cleaned is one of the best things you can do to ensure water drains properly away from the house and doesn’t end up in your basement.</p>"
];
export const serviceDisclaimers = [
  //"<p>The price is based on the estimated size of your property as well as the size of the primary structure and any outbuildings.</p>",
  "<p>The price is based on the estimated size of your property as well as the size of the primary structure and any outbuildings.</p><p>** if the service is done on the day of curbside cleanup, we may leave the bags at the curb</p>",
  "<p>This price is calculated based on the estimated property length adjacent to the street.</p><p>**if you do not have a sidewalk on your property, only the curb along the street will be edged.</p>",
  "<p>The price is calculated based on the estimated length of eavestrough.</p><p>**at this time, we are only serving single story homes.</p>"
];