import React from 'react';

class Header extends React.Component{
  render() {
    return (
      <div className="sticky-nav">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <nav className="navbar navbar-light">
              <button className="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
            { this.props.cart.length > 0 ? <button className="btn" data-toggle="modal" data-target="#exampleModal"><span className="icon"></span>Cart</button> : <button id="quote" className="btn">Get a Quote</button> }
          </div>
        </div>
        <div className="sticky-bg">
        </div>
      </div>
    )
  }
}
export default Header;