import React from 'react';
import { fetch_path } from './Fetch';

class Search extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      autocomplete: [],
      fallback: false
    }
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleKeyUp(event) {
    this.setState({value: event.target.value});
    if (event.target.value !== "") {
      fetch(fetch_path+'autocomplete.php', {
          method: 'POST',
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          body: 'search_value='+event.target.value
      }).then(res => {
        return res.json();
      }).then(data => {
        if (data.length < 1) {
          this.setState({fallback: true});
        }else{
          this.setState({fallback: false});
        }
        this.setState({autocomplete: data});
      });
    }else{
      this.setState({autocomplete: []});
    }
  }
  handleClick(event) {
    this.setState({
      value: event.target.innerHTML,
      autocomplete: []
    });
    this.props.onChange(event.target.innerHTML);
  }
  render() {
    const searchBar = (
      <div className="search elevation-z8">
        <div className="input-container row no-gutters">
                <input value={this.state.value} onChange={this.handleKeyUp} className="col" type="text" name="address" placeholder="Enter your address" />
                <input className="col-auto" type="submit" name="submit" value="" />
              </div>
              <div className="autocomplete elevation-z8">
                {
                  this.state.fallback ? (
                    <span>No available addresses</span>
                  )
                  :
                  (
                    this.state.autocomplete.map((address, index) =>
                      <span key={index} onClick={this.handleClick}>
                        {address.full_address}
                      </span>
                    )
                  )
                }
              </div>
            </div>
    );
    return (
        <div className="hero row align-items-end">
        <div className="container text-center">
          <img alt="City Lawn logo" className="img-fluid logo" src="images/logo-white.svg" />
          <div className="row justify-content-center">
            <div id="search" className="col-12 col-md-10 col-lg-8">{searchBar}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default Search;