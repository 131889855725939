import React from 'react';
import { fetch_path } from './Fetch';
//import { serviceList, serviceImgs, serviceBAs, serviceDescriptions, serviceDisclaimers } from './ServiceVars';

class Services extends React.Component{
  constructor(props){
    super(props);
    this.addToCart = this.addToCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.state = {
      serviceList: [],
      isLoaded: false,
    }
  }

  componentDidMount() {
    fetch(fetch_path+'service_vars.php', {
        method: 'GET',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }).then(res => {
      return res.json();
    }).then(data => {
      this.setState({
        serviceList: data,
        isLoaded: true,
      });
    });
  }

  addToCart(event){
    document.getElementById("cartSlide").classList.add("open");
    this.props.onAdd(this.props.products[event.target.dataset.item]);
  }
  removeFromCart(event){
    for (var i = this.props.cart.length - 1; i >= 0; i--) {
      if (this.props.cart[i].id === event.target.dataset.item) {
        this.props.onRemove(i, event.target.dataset.item);
      }
    }
    if (this.props.cart.length === 0) {
      document.getElementById("cartSlide").classList.remove("open");
    }
  }

  render() {
    const address = "Service for: "+this.props.address.selected;
    const active = this.props.active;
    const serviceChildren = this.state.serviceList.map((service, index) =>
        <div key={index} className="service col-12 col-md-4">
          <div className={active ? 'card text-center elevation-z8': 'card text-center'}>
            <h3>{service.name}</h3>
            <div className="img-container">
              <img alt="{service.name}" className="img-fluid" src={service.img} />
            </div>
            {this.props.active ? (
              this.props.products ? (
                this.props.products[index].offered ? (
                    <span className="price">${parseFloat(this.props.products[index].price).toFixed(2)}</span>
                  )
                  :
                  (
                    <span className="price small">Currently unavailable</span>
                  )
                )
              : null )
            : null }
            <div dangerouslySetInnerHTML={{__html: service.description}}></div>
            <div>
              {this.props.active ? (
                this.props.products ? (
                  this.props.products[index].offered ? (
                      this.props.products[index].selected ? (
                        <button onClick={this.removeFromCart} className="btn primary selected" data-item={this.props.products[index].id}></button>
                      )
                      :
                      (
                        <button onClick={this.addToCart} className="btn primary" data-item={this.props.products[index].id}>Add to cart</button>
                      )
                    )
                    :
                    (
                      <button className="btn primary disabled">Add to cart</button>
                    )
                  )              
                : null )
              : null }
            </div>
            {this.props.active ? (
              this.props.products ? (
                this.props.products[index].price !== 0 ? (
                  <div className="disclaimer" dangerouslySetInnerHTML={{__html: service.disclaimer}}></div>
                )
                : null )       
              : null )
            : null }
          </div>
        </div>
    );
    const serviceImages = this.state.serviceList.map((service, index) =>
      <img key={index} className="img-fluid" src={service.before_after} alt={service.name} />
    );
    return (
        <div id="services" className={active ? 'active': null}>
          <div className="container">
          <div className="row">
            <div className="col-12 text-center selected-address">
              {address}
              {this.props.address.multiple > 1 ? (
                <div className="small">Please note that we have identified multiple addresses at this location. The quoted price may be higher than expected as all dwellings at this location will be serviced.</div>
              ):null}
            </div>
          </div>
          <div className="row">
            {serviceChildren}
          </div>
          <div className="w-100 text-center">
            <button id="beforeAfter" type="button" className="btn btn-primary" data-toggle="modal" data-target="#beforeAfterModal">Before &amp; After</button>
          </div>
          <div className="modal fade" id="beforeAfterModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {serviceImages}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Services;