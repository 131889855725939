import React from 'react';
import { fetch_path } from './Fetch';

import { Braintree, HostedField } from 'react-braintree-fields';

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        [
            'onError',
            'getToken',
            'onCardTypeChange',
            'onAuthorizationSuccess',
        ].forEach(prop => (this[prop] = this[prop].bind(this)));
        this.state = {
            cart: this.props.cart
        }
        this.handleBlur = this.handleBlur.bind(this);
        this.handleNameBlur = this.handleNameBlur.bind(this);
    }
    onError(error) {
        if (error.details === undefined) {
            document.getElementById("cardholder_name").classList.add("is-invalid");
            var fields = document.getElementsByClassName("braintree-hosted-field");
            for (var i = fields.length - 1; i >= 0; i--) {
                fields[i].classList.add("is-invalid");
            }
        }else{
            for (var j = error.details.invalidFieldKeys.length - 1; j >= 0; j--) {
                document.getElementById("braintree-hosted-field-"+error.details.invalidFieldKeys[j]).parentNode.classList.add("is-invalid");
            }
        }
        this.setState({ error });
    }
    getToken() {
        var name = document.getElementById("cardholder_name").value;
        this.tokenize({
            cardholderName: name
        }).then(
            token => {
                this.setState({ token, error: null })
                fetch(fetch_path+'payment/transaction.php', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                    },
                    body: 'token='+JSON.stringify(token)+'&cart='+JSON.stringify(this.props.cart)+'&address='+this.props.address+'&info='+JSON.stringify(this.props.transaction_information)
                }).then(res => {
                    return res.json();
                }).then(data => {
                    console.log(data);
                    if (data === "success") {
                        this.props.onSuccess()
                    }
                });
            }
        ).catch(
            error => this.setState({ token: null, error }),
        );
    }
    handleBlur(event) {
        if (event.isValid === false) {
            event.container.classList.add("is-invalid");
        }else{
            event.container.classList.remove("is-invalid");
        }
    }
    handleNameBlur(event) {
    if(event.target.value.length === 0){
            event.target.classList.add("is-invalid");
        }else{
            event.target.classList.remove("is-invalid");
        }
    }
    onCardTypeChange({ cards }) {
        if (1 === cards.length) {
          const [card] = cards;

          this.setState({ card: card.type });

          if (card.code && card.code.name) {
            this.cvvField.setPlaceholder(card.code.name);
          } else {
            this.cvvField.setPlaceholder('CVV');
          }

        } else {
          this.setState({ card: '' });
          this.cvvField.setPlaceholder('CVV');
        }
    }

    componentDidMount() {
        this.setState({ authorization: 'production_pgyndng4_yhbk9mqgkxxtss2b' });
    }

    renderResult(title, obj) {
        const jsonReplacer = () => {
          const seen = new WeakSet();
          return (key, value) => {
            if (typeof value === "object" && value !== null) {
              if (seen.has(value)) {
                return;
              }
              seen.add(value);
            }
            return value;
          };
        };
        if (!obj) { return null; }
        return (
            <div>
                <b>{title}:</b>
                <pre>{JSON.stringify(obj, jsonReplacer())}</pre>
            </div>
        );
    }

    onAuthorizationSuccess() {
      //this.numberField.focus();
    }

    render() {
        return (
            <div className="modal-body">
                <Braintree
                    authorization={this.state.authorization}
                    onAuthorizationSuccess={this.onAuthorizationSuccess}
                    onError={this.onError}
                    getTokenRef={t => (this.tokenize = t)}
                    onCardTypeChange={this.onCardTypeChange}
                    styles={{
                        input: {
                            'font-size': '1rem',
                            'font-family': 'Lato, helvetica, tahoma, calibri, sans-serif',
                            color: '#495057',
                        },
                        ':focus': {
                            color: 'black',
                        },
                    }}
                >
                    <div>
                        <div className="form-group">
                            <label>Name on card:</label>
                            <input type="text" className="form-control" name="cardholder_name" id="cardholder_name" onBlur={this.handleNameBlur} />
                        </div>
                        <div className="form-group">
                            <label>Number:</label>
                            <HostedField type="number" className="form-control" placeholder="4111 1111 1111 1111" onBlur={this.handleBlur} ref={numberField => { this.numberField = numberField; }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Date:</label>
                            <HostedField type="expirationDate" className="form-control" placeholder="MM / YY" onBlur={this.handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>CVV:</label>
                            <HostedField className="form-control" type="cvv" placeholder="CVV" onBlur={this.handleBlur} ref={cvvField => { this.cvvField = cvvField; }} />
                        </div>
                    </div>
                </Braintree>
                <div className="modal-footer" style={{padding: 0}}>
                    <button onClick={this.props.goBack} type="button" className="btn white float-left">Return</button>
                    <button onClick={this.getToken} type="button" className="btn">Confirm</button>
                </div>
            </div>
        );
    }
}
export default Checkout;