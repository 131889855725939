import React from 'react';
import Header from './Header';
import Search from './Search';
import Services from './Services';
import Cart from './Cart';
import { fetch_path } from './Fetch';
import { serviceList } from './ServiceVars';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      address: {
        selected: "",
        multiple: 0
      },
      active: false,
      servicePrices: [],
      cart: [],
      total: 0,
      discount: 0,
      step: 1
    }
    this.changeAddress = this.changeAddress.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.confirm = this.confirm.bind(this);
  }
  componentDidMount() {
    this.hydrateStateWithLocalStorage();

    // add event listener to save state to localStorage
    // when user leaves/refreshes the page
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }
  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    // saves if component has a chance to unmount
    this.saveStateToLocalStorage();
  }
  hydrateStateWithLocalStorage() {
    // for all items in state
    for (let key in this.state) {
      // if the key exists in localStorage
      if (localStorage.hasOwnProperty(key)) {
        // get the key's value from localStorage
        let value = localStorage.getItem(key);

        // parse the localStorage string and setState
        try {
          value = JSON.parse(value);
          this.setState({ [key]: value });
        } catch (e) {
          // handle empty string
          this.setState({ [key]: value });
        }
      }
    }
  }
  saveStateToLocalStorage() {
    // for every item in React state
    for (let key in this.state) {
      // save to localStorage
      localStorage.setItem(key, JSON.stringify(this.state[key]));
    }
  }
  changeAddress(newAddress) {
    fetch(fetch_path+'services.php', {
        method: 'POST',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: 'new_address='+newAddress
    })
    .then(res => {
      return res.json();
    }).then(data => {
      this.setState({
        address: {
          selected: newAddress,
          multiple: data[0].addressesWithinParcel
        },
        active: true,
        servicePrices: [
          {
            id: 0,
            name: serviceList[0],
            price: data[0].lawn_cost_cap,
            offered: data[0].acceptLawn,
            selected: false,
            discountable: true
          },
          {
            id: 1,
            name: serviceList[1],
            price: data[0].edging_cost_cap,
            offered: data[0].acceptEdging,
            selected: false,
            discountable: true
          },
          {
            id: 2,
            name: serviceList[2],
            price: data[0].eavstrough_cost_cap,
            offered: data[0].acceptEavestrough,
            selected: false,
            discountable: true
          }
        ],
        cart: [],
        total: 0,
        discount: 0,
        step: 1
      });
    });
  }
  addToCart(newItem){
    let productsCopy = JSON.parse(JSON.stringify(this.state.servicePrices))
    let totalCopy = this.state.total+parseInt(newItem.price);
    let cartLength = this.state.cart.length;
    let cartDiscount = 0;
    if (cartLength+1 > 1) {
      cartDiscount = totalCopy*(5*cartLength)/100;
    }
    productsCopy[newItem.id].selected = true;
    if (cartLength === 0) {
      productsCopy[newItem.id].discountable = false;
    }else{
      productsCopy[newItem.id].discountable = true;
    }
    this.setState(previousState => ({
       servicePrices: productsCopy,
       cart: [...previousState.cart, newItem],
       discount: cartDiscount,
       total: totalCopy,
    }));
  }
  removeFromCart(removeCart, removeSelected){
    let productsCopy = JSON.parse(JSON.stringify(this.state.servicePrices))
    let cartCopy = this.state.cart;
    let cartLength = cartCopy.length;
    cartCopy.splice(removeCart, 1);
    productsCopy[removeSelected].selected = false;
    let totalCopy = this.state.total-parseInt(productsCopy[removeSelected].price)
    let cartDiscount = 0;
    if (cartLength-1 > 1) {
      cartDiscount = totalCopy*5/100;
    }
    if (cartLength === 0) {
      productsCopy[removeSelected].discountable = true;
    }else{
      productsCopy[removeSelected].discountable = true;
    }
    this.setState(previousState => ({
       servicePrices: productsCopy,
       cart: cartCopy,
       total: previousState.total-parseInt(productsCopy[removeSelected].price),
       discount: cartDiscount
    }));
  }
  confirm(){
    this.setState({
      address: {
        selected: "",
        multiple: 0
      },
      active: false,
      servicePrices: [],
      cart: [],
      total: 0,
      discount: 0,
      step: 1
    });
  }
  render() {
    return (
      <div>
        <Header cart={this.state.cart} />
        <Search onChange={this.changeAddress} />
        <Services onAdd={this.addToCart} onRemove={this.removeFromCart} address={this.state.address} active={this.state.active} products={this.state.servicePrices} cart={this.state.cart} total={this.state.total} />
        <Cart onChange={this.removeFromCart} onConfirm={this.confirm} cart={this.state.cart} total={this.state.total} discount={this.state.discount} address={this.state.address} step={this.state.step} />
      </div>
    );
  }
}

export default App;
